@import "../__variables";

.planCard,
.planDetailCard {
    padding: 0.5rem;

    .grid {
        align-items: center;

        p {
            margin-right: 1rem;
        }
    }

    .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &-badge {
        @include fontStyle("Roboto", 10px, #fff);
        padding: 5px;
        border-radius: 10px;
        margin-bottom: 0;
        height: 1.5rem;
        align-items: center;
        display: flex;
        width: min-content;

        &.exclusive {
            background-color: #9747ff;
            border-radius: 5px;
        }
    }

    .top {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-name {
        @include fontStyle("Roboto-Bold", 20px, #000);
        margin: 0;
    }

    &-dec {
        @include fontStyle("Roboto", 16px, #000);
        margin: 0;
        width: auto;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-count {
        @include fontStyle("Roboto", 16px, #000);
        line-height: 1;
        margin: 0;

        span {
            @include fontStyle("Roboto-Bold", 20px, #495057);
        }
    }

    &-info.grid {
        margin-top: auto;
    }

    &-date {
        @include fontStyle("Roboto-Bold", 16px, #495057);
        margin-bottom: 0;

        span {
            @include fontStyle("Roboto", 16px, #000);
        }
    }

    &-amount {
        margin-bottom: 0;
        @include fontStyle("Roboto", 14px, none);
        height: 1.5rem;

        span {
            margin-right: 0.1rem;
            @include fontStyle("Roboto-bold", 14px, none);
        }

        &.available .status-active {
            background-color: #c8e6c9;
            color: #689F38;
        }

        &.balance span {
            margin-left: 0;
        }

        &.pending .status-pending {
            color: #FBC02D;
        }

        .status-active,
        .status-pending {
            margin-right: -10px;
        }
    }

    &-highest {
        width: 15rem;
        padding: 0.2rem;
        border-radius: 3px;
        margin-bottom: 0;
        margin: 0px 1rem;
        @include fontStyle("Roboto-Bold", 1rem, none);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .grid {
            justify-content: space-between;

            span {
                @include fontStyle("Roboto-Bold", 1rem, none);
            }
        }

        p {
            @include fontStyle("Roboto-Bold", 2rem, none);
            margin-left: -0.5rem;
        }

        &-name {
            margin-left: -.5rem;
        }
    }

    &.p-card .p-card-body .p-card-content {
        padding: 0rem 0.5rem !important;
    }
}

.exclusive {
    height: 1.5rem;
}

#product-detail-side-menu {
    font-weight: bold;
    color: $rev_pri;
    padding: 0.4rem 0rem 0rem 3rem;

    span {
        cursor: pointer;

        &:hover {
            color: rgba($rev_pri, 0.3);
        }

        &:active {
            color: #0074ae;
        }
    }

    .pi {
        margin-right: 20px;
        font-weight: bold;
    }
}

.plans-name {
    font-family: "Roboto-Bold";
    margin-bottom: 3px;
}

body .plans-table-btn.p-button.p-button-text.p-button-plain {
    &:hover {
        color: #fff;
        background-color: #999;
    }
}

.plans-tabs-btn {
    background-color: #f7f7f7;
    padding: 1rem;
    margin-right: 1rem;
}

.plans-table-btn {
    margin-right: 1rem !important;
}

body .plan_detail_data_table {
    padding: 0px;
    width: 90%;

    .p-datatable-header {
        padding: 0px;
    }

    .p-datatable-wrapper {
        padding: 1rem;
    }

    a {
        color: $rev_blue;
        cursor: pointer;
    }
}

body #data_table.tier_detail_data_table {
    padding: 0px;
    margin-left: 1rem;

    a {
        color: $rev_blue;
        cursor: pointer;
    }

    .tier-name {
        @include fontStyle("Roboto-Bold", 14px, #000);
    }

    .tier-phone {
        @include fontStyle("Roboto-SemiBold", 14px, #000);
    }

    .tier-email {
        @include fontStyle("RobotoCondensed", 12px, #000);
    }

    .tier-date {
        @include fontStyle("Roboto-Bold", 14px, #000);
    }
}

.title-tile,
.products-title-tile {
    color: #008dd5;
    font-weight: bold;
    font-family: "Roboto";
    font-size: 12px;
    margin-top: 0;
    margin-left: 1rem;
}

.planDetailCard {
    .grid-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap: 0.5em 0em;
        grid-auto-flow: row;
        grid-template-areas:
            "top-left top-middle top-right"
            "bottom-left bottom-middle bottom-right";

        @media screen and (max-width: 1040px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .planDetailCard-date {
                position: relative;
            }

            .bottom-left,
            .bottom-middle,
            .bottom-right {
                width: auto;
            }
        }
    }

    .top-left {
        justify-self: stretch;
        grid-area: top-left;
    }

    .top-middle {
        grid-area: top-middle;
    }

    .top-right {
        grid-area: top-right;
    }

    .bottom-left {
        grid-area: bottom-left;
    }

    .bottom-middle {
        grid-area: bottom-middle;
    }

    .bottom-right {
        grid-area: bottom-right;
    }

    height: 100%;

    &-badge {
        &.tier {
            background-color: #008dd5;
            color: #fff;
            border-radius: 5px;
        }

        &.subscription {
            background-color: #9747ff;
            border-radius: 5px;
        }

        &.status-archived {
            background-color: #fcc12d;
            border-radius: 5px;
        }
    }

    &-dec {
        display: flex;
        margin: 0.3rem 0;
    }

    &-side-amount {
        @include fontStyle("Roboto-Bold", 20px, #495057);
        width: 15rem;
    }

    &-side-info {
        @include fontStyle("RobotoCondensed", 16px, #000, 600);
        line-height: 1;
        margin: 0;
        display: flex;
        margin-top: 0.5rem;
        height: 1.3rem;
        align-items: center;

        span {
            @include fontStyle("Roboto", 16px, #000);
            margin-left: 0.1rem;
        }
    }

    &-info.grid {
        margin-top: 2rem;
        align-content: center;
    }

    &-date {
        @include fontStyle("Roboto-Bold", 16px, #495057);
        margin-bottom: 0;
        margin-top: 0.3rem;
        display: flex;
        position: absolute;
        height: 1.3rem;

        &-data {
            @include fontStyle("Roboto", 16px, #000);
            margin-left: 0.2rem;
        }

        .planDetailCard-badge {
            margin-right: 0.2rem;
        }
    }

    .info-dot {
        font-size: 0.3rem;
        margin: auto 0.5rem;
        color: #666;
    }

    &-performance,
    .bottom-left,
    .bottom-middle,
    .bottom-right {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;

        &-col {
            display: flex;
            flex-direction: column;
            margin-top: auto;

            div {
                display: flex;
                align-items: center;
                @include fontStyle("Roboto-SemiBold", 12px, #008dd5);
                margin-bottom: 1.5rem;
            }
        }

        h6 {
            margin: 0rem;
        }

        i {
            margin-right: 4px;
            font-size: 20px;
        }

        p {
            margin-bottom: 0.5rem;
            @include fontStyle("RobotoCondensed", 16px, #495057);

            &.collected {
                color: $success;

                span {
                    @include fontStyle("RobotoCondensed-Bold", 12px, $success);
                    margin: 0px 5px;
                }
            }

            &.missed {
                color: $error;

                span {
                    @include fontStyle("RobotoCondensed-Bold", 12px, $error);
                    margin: 0px 5px;
                }
            }
        }
    }
}

.archive-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 0 0 0.5rem;
}

.archived-by {
    @include fontStyle("RobotoCondensed-Bold", 13px, #495057);
}

.desc-info {
    align-items: center !important;
}

.planDetailScreen {
    .wallet-titles {
        margin-left: 0px !important;
    }

    .report_cards {
        margin-left: -1rem;
    }

}

.planDetailCard-badge {
    width: 4rem;
    border-radius: 7px !important;
    color: #fff !important;
    font-size: 11px !important;
    font-weight: bold;
}

.planDetailCard-side-amount {
    font-family: "Roboto-Bold";
    font-size: 14px;
    color: #f6f6f6;
    background-color: #008dd5;
    font-weight: normal;
    padding: 0.35rem 0.85rem;
    text-transform: uppercase;
    border-radius: 8px;
    width: 8.2rem !important;
}

.planDetailCard-group-name {
    @include fontStyle("Roboto-Bold", 14px, #666678);
}

.amt {
    color: #d0021b;
    font-weight: bold;
}

.subs {
    @include fontStyle("Roboto", 12px, #666678);
    margin: 2px 0 0 0;
}

.card-cont {
    margin-left: 1rem;
}

.planDetailCard-side-info span {
    font-family: "Roboto";
    font-size: 16px;
    color: #000;
    font-weight: normal;
    margin-left: 0.35rem;
}

.planDetailCard-side-info {
    font-family: "RobotoCondensed";
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    display: flex;
    margin-top: 0.5rem;
    height: auto;
    align-items: center;
}

.date-label {
    @include fontStyle("Roboto", 15px, #000);
    font-weight: bold;
}

.insights {
    margin: 0rem 0 1rem 2rem;
    @include fontStyle("Roboto-Bold", 14px, #008DD5)
}

.summary-new, .summary-active, .summary-collected {
    @include fontStyle("Roboto-Bold", 14px, #689F38);
    margin-bottom: 1px;
}

.summary-paused,
.summary-completed,
.summary-pending {
    @include fontStyle("Roboto-Bold", 14px, #fcba04);
    margin-bottom: 1px;
}

.summary-cancelled,
.summary-aborted,
.summary-missed {
    @include fontStyle("Roboto-Bold", 14px, #d0021b);
    margin-bottom: 1px;
}

.summary-revenue {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
    margin-bottom: 1px;
}

.policyScheduleSpan {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    margin-left: 0.2rem;
    background-color: #eccfff;
    color: #694382;
    height: 1.5rem;
    border-radius: 5px;
    width: 6rem;
}

.policySchedule {
    display: flex;
    text-align: center;
    margin: 0.2rem 0.1rem 0rem 1rem;
    text-transform: uppercase;
}

.archivebtn_name {
    display: flex;
    flex-direction: row;
}

#archived_icon {
    color: #FDC237 !important;
    background-color: transparent !important;
    border: none;
    padding: 0rem;
    margin-right: 0.8rem;
    margin-top: 0.5rem;
    font-size: 18px;
}

#archived_icon>.p-button-icon {
    font-size: 20px;
}

#detail_btn {
    color: #008dd5 !important;
}

.product_date {
    @include fontStyle("Roboto-Bold", 14px, #666678);
}

.planModifiedDate {
    @include fontStyle("Roboto", 16px, #666678);
}

.status-design {
    padding: 14px 34px !important;
}

.productModifiedDate {
    @include fontStyle("Roboto", 14px, #000000);
}

.products-detail-card {
    height: fit-content;
    padding: 1rem;
}

.productCard-name {
    @include fontStyle("Roboto-Bold", 18px, #000000);
}

.planCard-dec {
    @include fontStyle("Roboto", 14px, #000000);
    padding-top: 0.2rem;
}

.pending-card-value {
    @include fontStyle("Roboto", 14px, #495057);
    margin: 0rem;
}

#available_label {
    @include fontStyle("Roboto-Bold", 14px, #689F38);
}

#pending_label {
    @include fontStyle("Roboto-Bold", 14px, #FBC02D);
}

.available-div {
    padding: 0rem 0rem 1rem 0.5rem;
}

.pending-div {
    padding-left: 0.6rem;
}

.balance-div {
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5rem;
}

.planCard-date {
    @include fontStyle("Roboto-Bold", 14px, #666678);
}

.green-success-txts {
    @include fontStyle("Roboto-Bold", 14px, #689F38);
}

.yellow-progress-txts {
    @include fontStyle("Roboto-Bold", 14px, #FBC02D);
}

.red-cancelled-txts {
    @include fontStyle("Roboto-Bold", 14px, #D0021B);
}

.blue-revenue-txt {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
}

.grey-completed-txt {
    @include fontStyle("Roboto-Bold", 14px, #666678);
}

.column-view {
    display: flex;
    flex-direction: column;
}

.card-values {
    @include fontStyle("Roboto-Bold", 18px, #000000);
}

.subsTitle {
    @include fontStyle("Roboto", 9px, #666678);
    padding-top: 0.3rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.subs-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.subsValues {
    @include fontStyle("Roboto-bold", 14px, #000000);
}

.currency {
    @include fontStyle("Roboto", 10px, #000000);
    padding-top: 0.4rem;
}

.productSummaryInfo {
    @include fontStyle("Roboto", 10px, #000000);
}

.summary-title {
    @include fontStyle("RobotoCondensed", 14px, #008dd5);
    font-weight: bold;
    margin-left: 1rem !important;
}

.payments-schedule-body {
    width: 16rem;
}

.schedule-date-body {
    width: 30rem;
}

.money-template {
    align-content: center;
}

.planCustomerTemplate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.payment-ref {
    @include fontStyle("Roboto-bold", 14px, #000);
    text-align: left;
    display: flex;
    cursor: pointer;
}

.payment-ref:hover {
    @include fontStyle("Roboto-bold", 14px, #008dd5);
    cursor: pointer;
}

.receivedAt-main-div {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.receivedAt-lastBilledOn-span {
    padding-bottom: 3px;
}

.receivedAt-lastBilledOn-span,
.receivedAt-spans {
    @include fontStyle("Roboto-bold", 12px, #000000);
}

.collectPaymentsTab>div>table>thead>tr>.plan_subscriber_info>div {
    justify-content: left !important;
}

.collectPaymentsTab>div>table>thead>tr>.plan_paid_on>div {
    justify-content: left !important;
}

.collectPaymentsTab>div>table>thead>tr>.plan_payment_id>div {
    justify-content: left !important;
}

.collectPaymentsTab>div>table>thead>tr>.plan_payment_id>div,
.collectPaymentsTab>div>table>thead>tr>.plan_redeemable>div,
.collectPaymentsTab>div>table>thead>tr>.plan_fees>div,
.collectPaymentsTab>div>table>thead>tr>.plan_collected>div,
.collectPaymentsTab>div>table>thead>tr>.plan_paymentMode>div {
    justify-content: left !important;
}

.plan_paymentMode_div {
    display: flex;
    align-items: flex-start;
}

.plan_paymentMode {
    @include fontStyle("Roboto", 14px, #000000);
}

.plan-name-link_bold,
.plan_customerNum {
    @include fontStyle("Roboto-bold", 14px, #000000);
    padding-bottom: 8px;
    display: flex;
    text-align: left;
}

.plan-name-link {
    @include fontStyle("Roboto-bold", 12px, #495057 !important);
    padding-bottom: 3px;
    display: flex;
    text-align: left;
}

.plan-name-link:hover {
    @include fontStyle("Roboto-bold", 12px, #008dd5 !important);
}

.plan-email {
    display: flex;
    text-align: left;
}

.plan-email,
.plan-phone {
    @include fontStyle("Roboto", 14px, #495057);
}

.plan-phone {
    padding-bottom: 3px;
}

.paid_on {
    display: flex;
    text-align: left;
}

.collected_amount {
    @include fontStyle("Roboto-bold", 12px, #66BB6A);
}

.plan_redeemable {
    @include fontStyle("Roboto-bold", 12px, #008DD5);
}

.plan_revenue {
    @include fontStyle("Roboto-bold", 12px, #D0021B);
}

.plans-tabs-btn {
    background-color: #f7f7f7;
    padding: 1rem;
    margin-right: 1rem;
}


#card-info-btn {
    cursor: pointer;
}

.column-view-mb {
    margin-bottom: 1.5rem;
}

#progress-spinner {
    width: 50px;
    height: 50px;
}

.side-menu-hr {
    background-color: #d8d8de;
    height: 1px;
}

.side-menu-content {
    padding: 1.5rem;
}

.action-titles {
    @include fontStyle("Roboto-Bold", 14px, #008dd5);
    display: flex;
    text-align: start;
    margin-top: 0rem;
}

#paymentContextIcon {
    margin-left: 2rem;
    cursor: pointer;
}

#paymentContextIcon:hover {
    margin-left: 2rem;
    cursor: pointer;
    color: #bd7f86;
}

.product_cards {
    background: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding: 15px 15px 10px 15px;
}

.planDetailScreen {
    .wallet-titles {
        margin-left: 0px !important;
    }

    .report_cards {
        background-color: white;
        margin: 0.5rem;
        display: grid;
        border-radius: 5px;
    }
}

#productDetail-info-card, #large-card-container {
    margin: 0rem 0rem 1rem 0rem;
    border-radius: 20px;
    width: 100% !important;
}

.product-main-card {
    display: flex;
    text-align: center;
    background-color: white;
}

.product-name-desc {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-top: 0.5rem;
}

.balance-value {
    @include fontStyle("Roboto", 20px, #495057);
}

.available_pending {
    margin-top: 1rem;
}

.pending-card-value,
.detail-card-value {
    @include fontStyle("Roboto", 14px, #495057);
}

.pending-card-value {
    @include fontStyle("Roboto", 14px, #495057);
}

.balance-main-div {
    display: flex;
    flex-direction: row;
}

#balance_label {
    @include fontStyle("Roboto-Bold", 20px, #000000);
}

.available-main-div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
}

.pending-main-div {
    display: flex;
    flex-direction: row;
}

.balance-side-div {
    display: flex;
    flex-direction: column;
}

.product-info-dates {
    display: flex;
    flex-direction: row;
}

.non-exclusive-tag {
    background-color: #9047f6;
    color: white;
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    font-size: 12px !important;
    font-family: "RobotoCondensed-Bold";
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.exclusive-tag {
    background-color: #2196F3;
    color: white;
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    font-size: 12px !important;
    font-family: "RobotoCondensed-Bold";
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.product-status {
    margin: 0rem 1rem 0rem 0rem;
}

.overlay-main::before,
.overlay-main::after {
    /* Changes the arrow color */
    border-color: transparent transparent #fff transparent;
    border-width: 6px !important;
}

#summary-subscription-section {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

#products-summary-section {
    margin: 1rem 0rem 0rem 0rem;
}

.report_cards>.products-report-content {
    background: #ffffff;
    color: #000000;
}

.products-report-content {
    background: #ffffff;
    border-radius: 5px;
    color: #000000;
    padding: 10px 0px 0px 12px;
    height: 14rem;
}

.left-grid {
    margin-bottom: 1rem;
}

.summary-records {
    padding: 8px 0px 0px 5px;
}

.product-card-titles {
    @include fontStyle("RobotoCondensed-bold", 12px, #000);
    padding-bottom: 20px;
}

.product-card-text {
    @include fontStyle("Roboto-bold", 18px, #000);
    padding-bottom: 5px;
}

.product-summary-card-subDesc {
    font-size: 9px;
}

div>div>div>.Plans {
    margin-right: 1rem !important;
}

.plan-cards-insights {
    margin: 1rem 0rem 0rem 0rem;
}

.planDetailCard-name {
    font-size: 17.5px;
    color: #000;
    font-weight: normal;
    margin: 0;
    line-height: 1;
    padding-bottom: 0.05rem;
}

.planDetailCard-side-amount {
    font-family: "Roboto-Bold";
    font-size: 14px;
    color: #f6f6f6;
    background-color: #008dd5;
    text-transform: uppercase;
    padding: 0.22rem 0.3rem;
    border-radius: 3px;
}

.planDetailCard-group-name {
    @include fontStyle("Roboto-Bold", 14px, #666678);
}

.amt {
    color: #d0021b;
    font-weight: bold;
}

.subs {
    @include fontStyle("Roboto", 12px, #666678);
    margin: 2px 0 0 0;
}

.card-cont {
    margin-left: 1rem;
}

.planDetailCard-side-info span {
    font-family: "Roboto";
    font-size: 16px;
    color: #000;
    font-weight: normal;
    margin-left: 0.35rem;
}

.date-label {
    @include fontStyle("Roboto", 15px, #000);
    font-weight: bold;
    margin-left: 5px;
}

.insights {
    margin: 0.5rem 0 0.5rem 0rem;
    @include fontStyle("Roboto-Bold", 14px, #008DD5)
}

.summary-new, .summary-active, .summary-collected {
    color: #689F38
}

.summary-paused,
.summary-completed,
.summary-pending {
    color: #fcba04
}

.summary-cancelled,
.summary-aborted,
.summary-missed {
    color: #d0021b
}

.summary-revenue {
    color: #008DD5
}

.amt-fig {
    @include fontStyle("Roboto-bold", 18px, #000)
}

.count-curren {
    @include fontStyle("Roboto", 10px, #000);
    padding: 0.4rem 0.2rem 0 0;
}

.count {
    font-weight: 700;
    color: #000;
    margin-right: 2px;
}

.plan-detail-card {
    margin-left: 0 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: red;
}

.insight-card-div {
    height: 17.5rem;
    padding: 0.5rem 0 0 0.5rem;
    background-color: #fff;
}

.plan-type {
    margin-top: auto;
    margin-bottom: 0.8rem;
}

#plandet-subscriptions {
    margin: 0 0 1.5rem 0;
}

.status-spacing {
    width: 6rem !important;
}

.subs-template-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
}

.plan-link {
    text-align: start;
    font-size: 14px;
    padding: 0px 0px 8px 0px;
    display: flex;
}

.customer-text {
    color: #666678;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
}

.subs-phone {
    color: #666678;
    font-family: Roboto;
    font-weight: 500;
    font-size: 12.5px;
}

.schedule-status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
}

.scheduled-for {
    color: #666678;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
}

.scheduled-date {
    color: #666678;
    font-family: Roboto;
    font-weight: 500;
    font-size: 12.5px;
}

.plan-nav {
    padding: 0px 0px 8px 0px;
    display: flex;
    text-align: start;
    font-size: 14px;
}

.cust-name {
    color: #000;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
}

.cust-phoneNo {
    @include fontStyle("Roboto-Bold", 12.5px, #666678);
}

.cust-scheduledFor {
    @include fontStyle("Roboto-Bold", 12px, #666678);
    line-height: 0rem;
}

.product_scheduledFor {
    @include fontStyle("Roboto", 12px, #666678);
    text-align: start;
}

.scheduledFor {
    @include fontStyle("Roboto", 12px, #666678);
    line-height: 0rem;
}

.scheduledFor_pb {
    padding-bottom: 0.8rem;
}

.failedStatusTemplate {
    display: flex;
    align-items: flex-start;
}

.col_amounts {
    display: flex;
    align-items: flex-start;
}

.failed_scheduleStatus {
    display: flex;
    width: 6rem;
    justify-content: center;
}

.cust-phoneNo {
    padding-bottom: 0.2rem;
}

.schedule-main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
}

.space {
    margin-bottom: 1px;
}

.space-start {
    text-align: center;
    margin-bottom: 7px;
}

.space {
    margin-bottom: 1px;
}

.space-start {
    text-align: center;
    margin-bottom: 7px;
}

.amt-fig {
    @include fontStyle("Roboto-bold", 18px, #000)
}

.count-curren {
    @include fontStyle("Roboto", 12px, #000);
    padding: 0.4rem 0.2rem 0 0;
}

.count {
    font-weight: 700;
    color: #000;
    margin-right: 2px;
}

.plan-card-main {
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
}

.insight-card-div {
    height: 17.5rem;
    padding: 0.5rem 0 0 0.5rem;
    background-color: #fff;
}

.ref-text {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
    cursor: pointer;
}

.status-spacing-content {
    width: 3rem !important;
    padding-right: 0px !important;
}

.ref-spacing {
    width: 12rem;
}

.subs-body-spacing {
    width: 28rem;
    min-width: 35rem;
}

.date-spacing {
    width: 10rem;
}

.status-bottom-text {
    text-align: start;
    margin-top: 5px;
}

.stb-label {
    font-size: 8.5px;
}

.align {
    text-align: end;
}

.stb-phone {
    font-weight: 500;
    font-size: 10px;
}

.failedTransactionColumn>div>table>thead>tr>.failed-transaction-column>div {
    justify-content: left !important;
}

.plan-Scheduled-for {
    @include fontStyle("Roboto-bold", 12px, #000000);
}

#plan-detail-mark-action {
    display: flex;
    vertical-align: top;
    cursor: pointer;
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
}

.upcoming-payment-date-div,
.planScheduledDate_div {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.upcoming-payment-date-span {
    padding-bottom: 3px;
}

.upcoming-payment-date,
.upcoming-payment-date-span {
    @include fontStyle("Roboto-bold", 12px, #000000);
}

.upcomingPaymentsColumn>div>table>thead>tr>.upcoming-payments-column>div {
    justify-content: left !important;
}

.upcoming-schedule-payments {
    width: 2rem;
    min-width: 10rem;
}

.upcoming_subs_status {
    width: 13rem;
}

.upcoming-amount {
    @include fontStyle("Roboto-bold", 14px, #000000);
}

.paymentSideMenu {
    display: flex;
    flex-direction: row;
}

.payments_p {
    color: #d0021b;
    line-height: 0rem;
    padding-top: 0.45rem;
}

.customerName-txt {
    margin-bottom: 3px;
}

.exclusive-tag {
    background-color: #2196F3;
    color: white;
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    font-size: 12px !important;
    font-family: "RobotoCondensed-Bold";
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.failed_transaction_status {
    width: 2rem;
    min-width: 10rem;
}