@import "../__variables";

.status-badge {
    border-radius: 10px;
    padding: 5px;
    @include fontStyle("Roboto", 10px, #000);
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;

    &.pills-available,
    &.pills-pending,
    &.pills-reversed,
    &.pills-completed {
        font-weight: 300;
        font-size: 6px;
        width: 5rem;
        display: flex;
        align-items: center;
    }

    &.pills-available {
        background-color: #c8e6c9;
        color: #256029;
    }

    &.pills-pending {
        background-color: #ffd8b2;
        color: #805b36;
    }
}

.status-completed,
.status-processed,
.status-complete,
.status-accepted,
.status-subscribed,
.status-renewed {
    background-color: #D8D8DE;
    color: #000;
}

.status-reversed {
    background-color: #83817b;
    color: #000;
}

.status-pending {
    background-color: #ffd8b2;
    color: #805b36;
}

.status-available {
    background-color: #b2eaff;
    color: #366180;
}

.status-archived {
    background-color: #ffd8b2;
    color: #805b36;
    border-radius: 10px;
    padding: 5px;
    @include fontStyle("Roboto", 10px, #000);
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
}

.type2 {
    margin-bottom: 0;
    @include fontStyle("Roboto-Bold", 14px, none);
    text-transform: uppercase;
    display: flex;
    flex-direction: row-reverse;

    span {
        margin-right: 0.1rem;
        @include fontStyle("Roboto-Bold", 14px, none);
    }

    &.available {
        color: #689F38;
        padding: 0;
        @include fontStyle("Roboto-Bold", 12px, none);
    }

    &.balance {
        text-transform: capitalize;
        padding: 0;
    }

    &.pending {
        color: #FBC02D;
        padding: 0;
        @include fontStyle("Roboto", 12px, none);
    }

    .status-active,
    .status-pending {
        margin-right: -10px;
    }
}