@import "../_variables";

.filterBox {
    min-width: 14rem;
    background: #fff;
    box-shadow: 0px 0px 7px 1px #00000050;
    display: flex;
    flex-direction: column;
    align-items: center;

    .p-column-filter-constraints,
    .p-column-filter-buttonbar {
        margin: 1rem;
        width: 15rem;
    }

    .p-column-filter-constraints {
        margin-bottom: 0rem;
    }

    .p-column-filter-buttonbar {
        display: flex;
        justify-content: space-between;
    }

    input,
    .p-calendar,
    .p-dropdown {
        height: 2.3rem;
        width: 15rem;
    }

    .p-autocomplete {
        height: min-content;
        width: 15rem;
    }

    .p-column-filter-operator {
        margin-top: 1rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container {
        padding: 0;
        display: flex;
    }

    .p-multiselect-panel .p-multiselect-header button.p-multiselect-close {
        margin-top: 0px;
    }

    .close-btn {
        margin-left: auto;
    }

    .field {
        margin: 1.5rem 0px;
    }

    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        margin: .5rem 0px;
    }
}

.subscriptionReport {
    .billingPeriod {
        color: $rev_sec;
        font-size: 0.8rem;
    }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
        background: #fff;
    }

    .subscriptionBodyTemplate {
        .name-body {
            cursor: pointer;
        }
    }

    .title-tile {
        font-size: 1rem;
    }
}

.card-titles {
    font-family: RobotoCondensed-Bold;
    font-size: 12px;
    margin-left: 5px;
}

.export-text {
    color: $rev_blue;
    font-family: Roboto-Bold;
    font-size: 11px;
    margin: auto;
}

.action-bar {
    margin: 0 1rem;
}

.report_calender>span>input {
    padding: 0.8rem;
}

.report_calender>span>button {
    height: 2.95rem;
}

.statusFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.referenceFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.requestedFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.channelFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.recipientFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.amountFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.activityFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

#transaction>.withdrawal_table .p-datatable-tbody>tr>td {
    text-align: left !important;
}